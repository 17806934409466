<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">LINE Pay残高</h3>
        <div class="title-left">
          <img src="../../../assets/images/line-pay.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">LINE Pay残高</h3>
            <p>LINE Pay残高は、LINE Payが使えるお店でご利用いただけることはもちろん、PayPay加盟店（一部を除くユーザースキャン方式の加盟店）でもご利用いただけます。また、LINEの友だちへの送金や銀行口座へのお振り込み、セブン銀行ATMにてご出金いただくこともできます（※）。<br>
              LINE Pay残高の詳しいご利用方法については、<a class="text-green" href="https://linepay.line.me/promotion/linepay-howtouse.html" target="_blank">こちら</a>をご覧ください。<br>
              ※ LINEの友だちへの送金、銀行振込、出金は、本人確認が完了したLINE Moneyユーザーに限ります。
            </p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong class="unit-gpoint"><div>1pt →</div><span>1円相当</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">500pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container d-inline-block">
          <p>■LINE Pay残高のお受け取りには、LINE Payアカウントが必要です。<br>
            詳しい説明については、<a href="https://pay.line.me/portal/jp/about/sign-up" target="_blank">こちら</a>をご覧ください。<br>
            <br>
            ■LINE Payには、2種類のアカウントタイプがあり、アカウントの種類によって利用できる機能や保有できるLINE Pay残高の上限が異なります。<br>
            ・それぞれの概要と機能の詳しいご説明については、<a href="https://help2.line.me/linepay_jp/android/pc?lang=ja&contentId=20021154" target="_blank">こちら</a>をご覧ください。<br>
            ・保有できるLINE Pay残高上限の詳しいご説明については、<a href="https://help2.line.me/linepay_jp/?contentId=20021203" target="_blank">こちら</a>をご覧ください。<br>
            ※LINE Pay残高のお受け取り時に、あらかじめ保有するLINE Pay残高と受け取る残高の合計額が、保有できる残高の上限を超える場合は、全額を受け取ることができませんのでご注意ください。<br>
            <br>
            ■LINE Pay株式会社が別途定める利用規約により、LINE PayサービスやLINE Payサービスに関するアカウント（LINEアカウントも含まれます）の利用が停止、禁止されている場合は、LINE Pay残高をご利用いただくことができませんのでご注意ください。<br>
            <br>
            ■LINE Pay残高のお受け取りには、お客様のLINE Pay ナンバーの情報を提供いただく必要がございます。<br>
            ・LINE Pay ナンバーの確認方法については、<a href="https://pay.line.me/portal/jp/business/support/manual/412" target="_blank">こちら</a>をご覧ください。<br>
            ・誤ったLINE Pay ナンバーを入力すると、残高をお受け取りいただけませんのでご注意ください。<br>
          </p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- BTN EXCHANGE POINT -->
        <template v-if="!showTextDisable">
          <button @click="handleBeforeExchangePoint"
                  v-if="profile && token"
                  class="btn-exchange-point">
            <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを交換する
          </button>
        </template>
        <p v-else class="text-disable">
          一時的にご利用できません。<br />
          しばらく時間をおいてお試しください。
        </p>
        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <!-- MODAL INPUT EXCHANGE POINT -->
    <Modal @close-modal="flagModalInput = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalInput">
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="validateBeforeSubmit()"
        slot="body"
        class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;LINE Pay残高</h3>
        <p class="sub-title">※LINE Pay残高への交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange">
          <span>交換</span>
          <InputField v-model="pointExchange"
                      field="交換ポイント"
                      type="text"
                      class="point-type"
                      :max-length="15"
                      @input="handleNumber($event)"
                      @focusout="handleNumberFocusOut()"
                      @focusin="handleNumberFocusIn($event)"
                      :rules="{
                        required: true,
                        number_exchange: true,
                        compare_point: {
                          first: pointExchange && parseInt(pointExchange.replace(/,/g, '')),
                          second: user_point.total_point_amount
                        },
                        range_point: true
                       }"
                      vid="point"/>
          <span class="pc">pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange point-line">
          <span>付与</span>
          <InputField v-model="pointExchange"
                      class="point-type"
                      :disabled="true"/>
          <span class="pc">円相当</span>
        </div>

        <!-- NOTE -->
        <p class="note">交換するポイントを入力して交換ポイントを確定するボタンをクリックしてください。</p>
      </ValidationObserver>

      <div class="btn-footer"
           slot="footer">
        <button @click.prevent="validateBeforeSubmit">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換ポイントを確定する
        </button>
      </div>
    </Modal>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalConfirm">
      <div slot="body"
           class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;LINE Pay残高</h3>
        <p class="sub-title">※LINE Pay残高への交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange confirm"
             :class="{ 'point-input' : !flagModalConfirm }">
          <span>交換</span>
          <strong class="point">{{ pointExchange }}</strong>
          <span>pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img v-if="flagModalConfirm" class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange confirm">
          <span>付与</span>
          <strong>{{ pointExchange }}</strong>
          <span>円相当</span>
        </div>

        <!-- NOTE MODAL CONFIRM -->
        <p class="note text-center">交換申請するボタンをクリックするとポイント交換が完了となります。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="linePaySNSRedirect">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請する
        </button>
      </div>
    </Modal>

    <!-- MODAL ALLOW EXCHANGE POINT -->
    <Modal @close-modal="resetPointAllow"
           class="modal-center modal-exchange-point"
           v-if="flagModalAllow">
      <!-- NOTE MODAL ALLOW EXCHANGE POINT -->
      <div slot="body"
           class="content-body dot-money">
        <p class="note text-center">LINE Pay残高の受け取りにあたり、<br>
          LINE Payナンバーをユーザー様に代わり、<br>
          LINE Pay株式会社から送金主催のChibiJob（株式会社ディー・エム広告社）へ<br>
          提供することに同意します。
        </p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="handleExchangePoint">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">同意する
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-exchange-point modal-center"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp">&#12288;LINE Pay残高</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ pointExchange | numberWithCommas }}<span class="sp color-orange">円相当</span></strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ new Date() | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange">円相当</span>
        </div>
        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
        <p class="text-instruct">
          実際に送金が完了しますと、「LINEウォレット」の公式アカウントへ、
          <br class="pc">
          送金完了の通知が届きます。
        </p>
      </div>
    </Modal>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
     <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>ポイント交換前に必要事項をご入力ください。</p>

            <button
              @click.prevent="handelRedirectUpdateInfo"
              v-html="'会員情報入力'"
              class="btn-into-modal"
            />
          </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
import { ChevronLeftIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import Modal from '@/components/advertising-detail/Modal'
import InputField from '@/components/form/InputField'
import SNS from '@/mixins/sns.mixin'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import { checkWebview } from '@/utils/helpers'
import { RESPONSE_STATUS } from '@/enum/response'

export default {
  name: 'Index',

  mixins: [Common, SNS],

  components: {
    ModalVerifyAuthenMobile,
    InputField,
    Modal,
    ChevronLeftIcon
  },

  computed: {
    ...mapState('linePay', ['referenceNumber', 'lineToken', 'point'])
  },

  mounted () {
    // Set timeout to app push linetoken on web
    if (checkWebview()) {
      setTimeout(() => {
        this.handleConfirmModal()
      }, 500)
    }
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  data () {
    return {
      flagModalAllow: !!((this.lineToken && this.referenceNumber)), // show on app
      showTextDisable: false
    }
  },

  methods: {
    ...mapActions('linePay', ['linepayExchangePoint']),

    resetPointAllow () {
      this.pointExchange = null
      this.flagModalAllow = false
    },

    async handleConfirmModal () {
      // handle login line webview
      if (this.lineToken) {
        // call API get reference number
        const params = {
          access_token: this.lineToken
        }
        store.dispatch('linePay/getReferenceNumber', params)
        // await store.commit('linePay/SET_REFERENCE_NUMBER', '11040004001_SB')
        this.pointExchange = this.point
        this.flagModalAllow = true
        store.commit('linePay/SET_LINE_TOKEN', null)
      }
    },

    async handleExchangePoint () {
      this.flagModalAllow = false
      const params = {
        // reference_no: '11040004001_SB',
        // reference_no: this.referenceNumber,
        reference_no: this.referenceNumber.data.referenceNo,
        point: (typeof this.pointExchange === 'string') ? parseInt(this.pointExchange.replace(/,/g, '')) : this.pointExchange // remove comma in number on web
      }

      const exchangeResult = await this.linepayExchangePoint(params)

      if (exchangeResult.status === RESPONSE_STATUS.OK) {
        this.flagModalComplete = true

        this.dataComplete = {
          ...exchangeResult.data
        }

        if (this.checkWebview()) {
          this.triggerEventApp('CHANGE_POINT', {})
        } else this.userPoint()

        // clear reference number
        this.$store.commit('linePay/SET_REFERENCE_NUMBER', null)
      } else {
        this.flagModalNoti = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';
</style>
